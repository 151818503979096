import React from 'react';
import PropTypes from 'prop-types';
import { Container, Flex, Box } from 'theme-ui';

import BlogItem from './BlogItem';
import Dropdown from './Dropdown';

const CategoryIndex = ({ blogs, basePath, categories, path }) => (
  <>
    <Box as="section" bg="bg.grey" py={4}>
      <Container
        as="h1"
        variant="content"
        sx={{ fontSize: 4, fontWeight: 'bold', px: [3, 3, 0] }}
      >
        Explore our blog
      </Container>
    </Box>
    <Container
      as="section"
      variant="content"
      py={3}
      sx={{ display: 'flex', px: [3, 3, 0] }}
    >
      <Dropdown items={categories} path={path} />
    </Container>
    <Container as="section" variant="content" sx={{ p: [3, 3, 0], mt: 5 }}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          m: -4,
        }}
      >
        {blogs.map((blog) => (
          <BlogItem key={blog.id} blog={blog} basePath={basePath} />
        ))}
      </Flex>
    </Container>
  </>
);

CategoryIndex.propTypes = {
  blogs: PropTypes.array.isRequired,
  basePath: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default CategoryIndex;
