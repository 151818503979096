/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { jsx, Container, Button, Text } from 'theme-ui';
// import { context, linkResolver } from 'gatsby-theme-data';
// import Contact from '../../../components/Contact';
import InterfaceContext from '../../context/InterfaceContext';
import linkResolver from '../../utils/linkResolver';

// const { default: InterfaceContext } = context;

const Header = ({ title, author, category, image, basePath, file }) => (
  <InterfaceContext.Consumer>
    {({ toggle, isOpen, pageURL }) => (
      <Container as="section" variant="content" sx={{ mt: 5 }}>
        {/* <Contact
            toggle={toggle}
            status={isOpen ? 'opened' : 'closed'}
            pageURL={pageURL}
            downloadURL={file}
          /> */}
        <div
          dangerouslySetInnerHTML={{ __html: title.html }}
          sx={{
            maxWidth: 900,
            mx: 'auto',
            my: 3,
            '& h1': { color: 'grey', fontSize: 5, textAlign: 'center' },
          }}
        />
        <Text
          as="p"
          sx={{
            textAlign: 'center',
            mb: 4,
            fontSize: 1,
            '& a': {
              fontWeight: 'bold',
              color: 'grey',
            },
            '& a:hover': {
              color: 'accent',
            },
          }}
        >
          by{' '}
          <Link to={linkResolver(author.document)}>
            {author.document.data.name}
          </Link>
        </Text>
        {category.document && (
          <div sx={{ textAlign: 'center', mb: 4 }}>
            <Button
              variant="category"
              as={Link}
              to={`${basePath}/${category.document.uid}`}
              sx={{ backgroundColor: category.document.data.color }}
            >
              {category.document.data.name}
            </Button>
          </div>
        )}

        {image.localFile && (
          <Img fluid={image.localFile.childImageSharp.fluid} />
        )}
        {image.fluid && <Img fluid={image.fluid} />}
      </Container>
    )}
  </InterfaceContext.Consumer>
);

Header.propTypes = {
  title: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default Header;
