/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Container, Flex, Box, Text } from 'theme-ui';
import shortid from 'shortid';

import BlogItem from './BlogItem';
import BlogItemCTA from './BlogItemCTA';
import Dropdown from './Dropdown';

// import { Search } from 'gatsby-theme-data';

const searchIndices = [
  { name: `Posts`, title: `Blog Posts`, hitComp: `PostHit` },
];

const BlogIndex = ({
  data,
  blogs,
  blogCTAs,
  basePath,
  isFirstPage,
  categories,
  path,
}) => {
  const recent =
    isFirstPage &&
    blogs
      .filter((item) => item.data.featured_image.localFile !== null)
      .splice(0, 2);

  const recentIDs = isFirstPage && recent.map((post) => post.id);

  blogs = isFirstPage
    ? blogs.filter((post) => !recentIDs.includes(post.id))
    : blogs;

  const { title } = data;

  return (
    <>
      <Box
        as="section"
        bg="bg.grey"
        py={4}
        sx={{ display: ['block', null, 'none'] }}
      >
        <Container
          as="h1"
          variant="content"
          sx={{ fontSize: 4, fontWeight: 'bold', px: [3, 3, 0] }}
        >
          {title.text}
        </Container>
      </Box>
      <Container
        as="section"
        variant="content"
        py={3}
        sx={{
          px: [3, 0, 0],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ['flex-start', 'center'],
          flexDirection: ['column', 'row'],
          maxWidth: 'contentWidth',
        }}
      >
        {categories.length > 0 && <Dropdown items={categories} path={path} />}
        {/* <Search
          indices={searchIndices}
          basePath={basePath}
          sx={{ width: ['100%', 'auto'] }}
        /> */}
      </Container>
      {isFirstPage && (
        <Container
          as="section"
          variant="content"
          sx={{
            p: [3, 0, 0],
            maxWidth: 'contentWidth',
          }}
        >
          <Text
            sx={{
              my: 3,
              fontSize: 4,
              fontWeight: 'bold',
              color: 'lightGrey',
              textTransform: 'uppercase',
            }}
          >
            Recent
          </Text>
          <Flex
            sx={{
              flexWrap: 'wrap',
              m: -4,
            }}
          >
            {recent.map((blog) => (
              <BlogItem
                key={blog.id}
                variant="big"
                blog={blog}
                basePath={basePath}
              />
            ))}
          </Flex>
        </Container>
      )}
      <Container
        as="section"
        variant="content"
        sx={{
          p: [3, 3, 0],
          mt: 5,
          maxWidth: 'contentWidth',
        }}
      >
        <Flex
          sx={{
            flexWrap: 'wrap',
            m: -4,
          }}
        >
          {blogs.map((blog, index) => {
            return isFirstPage && index === 1 ? (
              <React.Fragment key={blog.id}>
                <BlogItem blog={blog} basePath={basePath} />
                {blogCTAs.map((cta) => {
                  const {
                    item_title: itemTitle,
                    action_label: actionLabel,
                    action_url: actionURL,
                    color_scheme: colorScheme,
                  } = cta.primary;
                  return (
                    <BlogItemCTA
                      key={shortid.generate()}
                      title={itemTitle}
                      actionLabel={actionLabel}
                      actionURL={actionURL}
                      colorScheme={colorScheme}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              <BlogItem key={blog.id} blog={blog} basePath={basePath} />
            );
          })}
        </Flex>
      </Container>
    </>
  );
};

BlogIndex.propTypes = {
  blogs: PropTypes.array.isRequired,
  blogCTAs: PropTypes.array.isRequired,
  basePath: PropTypes.string.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
};

export default BlogIndex;
