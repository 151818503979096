/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { jsx, Button, Flex, Box, Text } from 'theme-ui';

const BlogItem = ({ blog, basePath, variant }) => {
  const {
    uid,
    data: { title, excerpt, category, featured_image: image },
  } = blog;
  const blogURL = category.uid
    ? `${basePath}/${category.uid}/${uid}`
    : `${basePath}/${uid}`;

  const big = variant === 'big';
  return (
    <Box
      sx={{
        width: [`100%`, `50%`, big ? '50%' : `${(1 / 3) * 100}%`],
        p: 4,
        flex: [
          `0 0 100%`,
          `0 0 50%`,
          big ? '0 0 50%' : `0 0 ${(1 / 3) * 100}%`,
        ],
      }}
    >
      <Link to={blogURL} sx={{ display: 'block' }}>
        {image && image.localFile ? (
          <Img fluid={image.localFile.childImageSharp.fluid} />
        ) : (
          <Flex
            sx={{
              p: 3,
              justifyContent: 'center',
              alignItems: 'center',
              border: (theme) => `4px solid ${theme.colors.accent}`,
              minHeight: big ? 284 : 178,
              textAlign: 'center',
              color: 'accent',
            }}
          >
            <Text
              as="p"
              variant="blogItem.titleBig"
              sx={{ fontSize: big ? 5 : 4 }}
            >
              {title.text}
            </Text>
          </Flex>
        )}
        {category.document && (
          <Button
            variant="category"
            as="div"
            to={`${basePath}/${category.uid}`}
            sx={{ bg: category.document.data.color, color: 'white', my: 3 }}
          >
            {category.document.data.name}
          </Button>
        )}
        <Text
          as="h2"
          variant="blogItem.title"
          sx={{ mt: category.document ? 0 : 3 }}
        >
          {title.text}
        </Text>
        <Text as="p" color="lightGrey">
          {excerpt}
        </Text>
      </Link>
    </Box>
  );
};

BlogItem.propTypes = {
  blog: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['big', 'normal']),
};

BlogItem.defaultProps = {
  variant: 'normal',
};

export default BlogItem;
