import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import PageBody from '../components/PageBody';
import { Header, BlogBodyContainer } from '../components/Blog';
// import usePreviewData from '../utils/usePreviewData';

const BlogTemplate = ({ data, pageContext, location }) => {
  // const liveData = usePreviewData(data);

  const {
    prismicBlog: { data: page },
  } = data;//liveData;

  if (!page) return null;

  const {
    title,
    excerpt,
    author,
    category,
    featured_image: image,
    file,
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = page;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={metaTitle || title.text}
        description={metaDescription || excerpt}
        image={
          image.localFile
            ? image.localFile.childImageSharp.original.src
            : undefined
        }
      />
      <Header
        title={title}
        author={author}
        category={category}
        image={image}
        basePath={pageContext.basePath}
        file={file}
      />
      <BlogBodyContainer>
        <PageBody document={page} />
      </BlogBodyContainer>
    </>
  );
};

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogTemplate;

export const query = graphql`
  query BlogBySlug($uid: String!, $lang: String!) {
    prismicBlog(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          html
          text
        }
        excerpt
        featured_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
        }
        author {
          document {
            ... on PrismicPerson {
              uid
              type
              data {
                name
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              id
              uid
              data {
                name
                color
              }
            }
          }
        }
        file {
          url
        }
        meta_title
        meta_description
        social_card {
          alt
          localFile {
            url
          }
        }

        body {
          ... on PrismicBlogBodyCta {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              subheading
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicBlogBodyTextBlock {
            id
            slice_type
            primary {
              heading {
                html
              }
              content {
                html
              }
              text_align
            }
          }
          ... on PrismicBlogBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              description
            }
          }
          ... on PrismicBlogBodyVideo {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              video_url {
                html
              }
            }
          }
          ... on PrismicBlogBodyPositiveNegative {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              positive_title
              positive_content
              negative_title
              negative_content
            }
          }
        }
      }
    }
  }
`;
