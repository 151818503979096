/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

const BlogBodyContainer = ({ children }) => (
  <div
    sx={{
      'h1, h2, h3, h4': {
        color: 'brand',
        mt: 5,
      },
      'h1, h2': { mt: 6 },
      h1: { mt: 0 },
      'ul, ol': {
        textAlign: 'left',
        li: {
          mb: 4,
        },
      },
    }}
  >
    {children}
  </div>
);

export default BlogBodyContainer;
