import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button, Flex, Box, Text } from 'theme-ui';
import { linkResolver } from '../../utils/linkResolver';

const BlogItemCTA = ({ title, actionLabel, actionURL, colorScheme }) => {
  const isPositive = colorScheme === 'positive';
  return (
    <Box
      sx={{
        width: [`100%`, `50%`, `${(1 / 3) * 100}%`],
        p: 4,
        flex: [`0 0 100%`, `0 0 50%`, `0 0 ${(1 / 3) * 100}%`],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          p: 4,
          bg: isPositive ? 'white' : 'accent',
          height: '100%',
          minHeight: 350,
          border: (theme) => isPositive ? `4px solid ${theme.colors.accent}` : 'none',
        }}
      >
        <Text
          as="h2"
          color={isPositive ? 'accent' : "white"}
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            fontSize: 5,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Text>
        {actionURL.type ? (
          <Button
            variant="primary"
            as={Link}
            to={linkResolver(actionURL)}
            sx={{
              bg: 'white',
              color: 'accent',
              '&:hover': { color: 'white' },
            }}
          >
            {actionLabel || 'link'}
          </Button>
        ) : (
          <Button
            variant="primary"
            as="a"
            href={actionURL.url}
            target={actionURL.target}
            sx={{
              bg: isPositive ? 'accent' : 'white',
              color: isPositive ? 'white' : 'accent',
              '&:hover': { color: 'white' },
            }}
          >
            {actionLabel || 'link'}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

BlogItemCTA.propTypes = {
  title: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionURL: PropTypes.object.isRequired,
  colorScheme: PropTypes.string.isRequired,
};

export default BlogItemCTA;
