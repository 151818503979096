/** @jsx jsx */

import React from 'react';
import shortid from 'shortid';
import { Link } from 'gatsby';
import { jsx, Flex, Text } from 'theme-ui';
import ArrowDown from '../../icons/ArrowDown';

const Dropdown = ({ items, path }) => {
  const selected = items.find((item) => item.path === path);
  return (
    <Flex sx={{ alignItems: 'center', mb: [4, 0, 0] }}>
      <Text sx={{ py: 2, fontSize: 1, fontWeight: 'bold' }}>Browse</Text>

      <div
        sx={{
          position: 'relative',
          '&:hover .dropdown-content': {
            display: 'block',
          },
        }}
      >
        <span
          sx={{
            display: 'inline-block',
            px: 3,
            py: 2,
            fontSize: 1,
            fontWeight: 'bold',
            color: 'accent',
          }}
        >
          {selected ? selected.name : 'Everything'}
        </span>
        <ul
          className="dropdown-content"
          sx={{
            m: 0,
            bg: 'white',
            fontSize: 1,
            fontWeight: 'bold',
            listStyle: 'none',
            display: 'none',
            position: 'absolute',
            minWidth: 200,
            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            px: 3,
            zIndex: 100,
          }}
        >
          {items.map((item) => (
            <li key={shortid.generate()}>
              <Link
                to={item.path}
                sx={{
                  display: 'block',
                  py: 2,
                  color: 'grey',
                  '&:hover': { color: 'accent' },
                }}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <ArrowDown />
    </Flex>
  );
};

export default Dropdown;
